<template>
  <div>
    <e-filters
      :searching="fetching"
      @search="filterData"
      @reset="resetFiltersLocal"
    >
      <b-row>
        <b-col md="6">
          <FormulateInput
            id="name"
            v-model="filters.name"
            :label="$t('Nome do Grupo')"
            :placeholder="$t('Nome')"
          />
        </b-col>
      </b-row>
    </e-filters>

    <b-card-actions
      action-refresh
      :show-loading="fetching"
      @refresh="filterData"
    >
      <div slot="title">
        <e-page-size-selector
          :per-page="paging.pageSize"
          @change="pageSizeChange"
        />
      </div>
      <b-table
        ref="group-table"
        show-empty
        responsive
        striped
        class="bordered"
        :empty-text="getEmptyTableMessage($tc('GROUP.NAME'))"
        :fields="fields"
        :items="productGroups"
        no-local-sorting
        :sort-by.sync="sorting.sortBy"
        :sort-desc.sync="sorting.sortDesc"
        @sort-changed="getData"
      >
        <template #cell(image)="row">
          <b-img-lazy
            :src="row.item.image || noImage"
            rounded
            center
            width="45px"
            height="45px"
            style="object-fit: contain; max-height: 150px"
          />
        </template>

        <template #cell(active)="row">
          <e-status-badge :status="row.item.active" />
        </template>

        <template #cell(action)="row">
          <e-grid-actions
            :is-active="row.item.active"
            :show-update="$can('Update', 'ProductGroup')"
            :show-delete="$can('Delete', 'ProductGroup')"
            :show-activate="$can('Activate', 'ProductGroup')"
            :show-deactivate="$can('Deactivate', 'ProductGroup')"
            @update="showUpdateGroupSidebar(row)"
            @delete="deleteGroup(row.item)"
            @activate="activateDeactivateGroup(row)"
            @deactivate="activateDeactivateGroup(row)"
          />
        </template>
      </b-table>
      <b-row>
        <b-col cols="6">
          <e-pagination-summary
            :current-page="paging.currentPage"
            :per-page="paging.pageSize"
            :total="paging.rowCount"
            :total-on-page="paging.rowsInCurrentPage"
          />
        </b-col>
        <b-col cols="6">
          <b-pagination
            v-model="paging.currentPage"
            align="right"
            :total-rows="paging.rowCount"
            :per-page="paging.pageSize"
            aria-controls="product-group-table"
            @change="pageChange"
          />
        </b-col>
      </b-row>
    </b-card-actions>
    <product-group-sidebar
      ref="group_sidebar"
      @save="getData"
    />

    <fab
      v-if="$can('Create', 'ProductGroup')"
      :main-tooltip="$t('Adicionar Grupo')"
      @click="showCreateGroupSidebar"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { BRow, BCol, BPagination, BTable, BImgLazy } from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { EPageSizeSelector, EPaginationSummary, EFilters, EGridActions } from '@/views/components'
import fab from '@/views/components/FAB.vue'
import { uploader } from '@/mixins'
import EStatusBadge from '@/views/components/EStatusBadge.vue'
import ProductGroupSidebar from './ProductGroupSidebar.vue'

export default {
  components: {
    BRow,
    BCol,
    BCardActions,
    BPagination,
    BImgLazy,
    BTable,
    EPaginationSummary,
    EPageSizeSelector,
    fab,
    ProductGroupSidebar,
    EFilters,
    EGridActions,
    EStatusBadge,
  },

  mixins: [uploader],

  data() {
    return {
      showSidebar: false,
      fetching: false,
      edit: false,
      saving: false,
      imageLocal: [],
      // eslint-disable-next-line global-require
      noImage: require('@/assets/images/noimage.png'),
    }
  },
  computed: {
    ...mapState('pages/catalog/productGroups', {
      productGroups: 'productGroups',
      paging: 'paging',
      sorting: 'sorting',
      filters: 'filters',
    }),
    fields() {
      return [
        {
          label: this.$t('Ações'),
          key: 'action',
          class: 'text-center',
          thStyle: { width: '100px' },
        },
        {
          label: this.$t('Id'),
          key: 'id',
          class: 'text-center',
          thStyle: { width: '100px' },
          sortable: true,
        },
        {
          label: this.$t('Imagem'),
          key: 'image',
          class: 'text-center',
          thStyle: { width: '200px' },
        },
        {
          label: this.$t('Nome'),
          key: 'name',
          trClass: 'text-center',
          tdClass: 'text-left',
          sortable: true,
        },
        {
          label: this.$t('Status'),
          key: 'active',
          class: 'text-center',
          thStyle: { width: '90px' },
          sortable: true,
        },
      ]
    },
    selectedGroupId() {
      return this.group?.id
    },
  },

  watch: {
    group(val) {
      this.imageLocal = val?.image ? [this.getFileInstance(val.image)] : []
    },
  },

  mounted() {
    this.getData()
  },

  methods: {
    ...mapActions('pages/catalog/products', { comboFetchDomainData: 'fetchDomainData' }),
    ...mapActions('pages/catalog/productGroups', [
      'fetchProductGroups',
      'setCurrentPage',
      'setPageSize',
      'resetFilters',
    ]),
    async getData() {
      this.$nextTick(async () => {
        try {
          this.fetching = true
          await this.fetchProductGroups()
        } catch (error) {
          this.showGenericError({ error })
        } finally {
          this.fetching = false
        }
      })
    },

    filterData() {
      this.setCurrentPage(1)
      this.getData()
    },

    resetFiltersLocal() {
      this.resetFilters()
      this.filterData()
    },

    showCreateGroupSidebar() {
      this.$refs.group_sidebar.show()
    },

    async showUpdateGroupSidebar(row) {
      this.$refs.group_sidebar.show({ id: row.item.id })
    },

    pageSizeChange(pageSize) {
      this.setPageSize(pageSize)
      this.getData()
    },

    pageChange(currentPage) {
      this.setCurrentPage(currentPage)
      this.getData()
    },

    async deleteGroup(group) {
      try {
        const confirm = await this.confirm()
        if (confirm) {
          await this.$http.delete(`/api/product-groups/${group.id}`)
          this.comboFetchDomainData('product-groups')
          this.getData()
          this.showSuccess({ message: this.$t('Grupo removido com sucesso') })
        }
      } catch (error) {
        this.showError({ error })
      }
    },

    async activateDeactivateGroup(row) {
      try {
        const confirm = await this.confirm()
        if (confirm) {
          const path = row.item.active ? 'deactivate' : 'activate'
          await this.$http({
            url: `/api/product-groups/${path}/${row.item.id}`,
            method: 'PUT',
          })
          this.comboFetchDomainData('product-groups')
          this.getData()
          this.showSuccess({
            message: this.$t(`${row.item.active === true ? 'Inativado' : 'Ativado'} com sucesso`),
          })
        }
      } catch (error) {
        this.showError({ error })
      }
    },
  },
}
</script>

<style></style>
